import React, {
  useEffect,
  useContext,
  useCallback,
  useState,
  Fragment,
  useRef,
} from 'react'
import styled from 'styled-components'
import Transition from 'react-transition-group/Transition'
import { ContactContext } from '@components/Contact/Contact.Context'
import media from '@styles/media'
import { copyToClipboard, scrollable } from '@utils'
import { ExIcon } from '../../icons/ui'
import { useReduxState } from '@store'
import Section from '@components/Section/Section'
import Gradient from '@components/Gradient/Gradient'
import Logo from '@components/Logo/Logo'
import settings from '../../settings'
import theme from '@styles/theme'

const contacts = [
  {
    heading: 'Fey enquiries',
    email: 'team@feyapp.com',
  },
  {
    heading: ' Work at Narative',
    email: ' careers@narative.co',
  },
  {
    heading: 'Business enquiries',
    email: 'contact@narative.co',
  },
]

function ContactSlideIn() {
  const { showContact, toggleContact } = useContext(ContactContext)
  const [{ name }] = useReduxState(state => ({
    name: state.shortcuts.name,
  }))

  useEffect(() => {
    if (name === 'CONTACT' && !showContact) {
      scrollable('disable')
      toggleContact()
      return
    }

    if (showContact) {
      toggleContact()
    }

    if (!name.includes('COMMAND_LINE')) {
      scrollable('enable')
    }
  }, [name])

  const [copied, setCopied] = useState({})
  const handleCopy = useCallback((email: string, index: number) => {
    copyToClipboard(email)
    setCopied(copied => ({ ...copied, [index]: true }))

    setTimeout(() => {
      setCopied(copied => ({ ...copied, [index]: false }))
    }, 1600)
  }, [])

  const [password, togglePassword] = useState(false)
  const [value, setValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (value === 'explore') {
      window.open(
        'https://www.figma.com/proto/XFbvuffIOmzfyskIj022Ad/How-We-Work?node-id=1%3A371&viewport=1130%2C650%2C0.04201505705714226&scaling=contain&hide-ui=1',
        '_blank'
      )
      togglePassword(false)
      setValue('')
    }
  }, [value])

  useEffect(() => {
    if (password) {
      setValue('')
      inputRef.current.focus()
    }
  }, [password, inputRef])

  const handleClose = () =>
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }))

  return (
    <Frame
      tabIndex={showContact ? 0 : -1}
      aria-hidden={!showContact}
      showContact={showContact}
    >
      {' '}
      <Mask isActive={showContact} onClick={handleClose} />
      <SlideIn in={showContact}>
        <CloseContainer onClick={handleClose}>
          <EscIcon /> <ExIcon fill="#fff" />
        </CloseContainer>

        <ContactSection>
          <Container>
            <Column>
              <LogoContainer onClick={handleClose}>
                <Logo onlySymbol />
              </LogoContainer>
              <Heading>
                <Gradient>Get in touch</Gradient>
              </Heading>
              <ResourceContainer>
                <HeadingSmall>Resources</HeadingSmall>
                <ResourceLink
                  style={{ marginBottom: 0 }}
                  onClick={() => {
                    togglePassword(password => !password)
                  }}
                >
                  How we work <FigIcon />
                </ResourceLink>
                <PasswordContainer
                  style={{
                    opacity: password ? 1 : 0,
                    transform: `translateX(${password ? 0 : -6}px)`,
                  }}
                >
                  <ChevronRight />{' '}
                  <PasswordInput
                    value={value}
                    onChange={event => setValue(event.target.value)}
                    ref={inputRef}
                    type="password"
                    placeholder="Enter password"
                    maxLength={8}
                  />
                </PasswordContainer>
              </ResourceContainer>
            </Column>

            <Column>
              <div></div>
              <ContactContainer>
                {contacts.map((contact, index) => {
                  return (
                    <Fragment key={contact.email}>
                      <HeadingSmall>{contact.heading}</HeadingSmall>
                      <ContactListEmail
                        onClick={() => handleCopy(contact.email, index)}
                      >
                        <CopyIconContainer>
                          <CopyIcon />
                        </CopyIconContainer>
                        {contact.email}
                        <CopyTextContainer
                          style={{ opacity: copied[index] ? 1 : 0 }}
                        >
                          copied
                        </CopyTextContainer>
                      </ContactListEmail>
                    </Fragment>
                  )
                })}
              </ContactContainer>
              <Desktop>
                <SocialDivider />
                <HeadingSmall>Follow Us</HeadingSmall>
                <SocialContainer>
                  <SocialLink
                    href={settings.urls.twitter}
                    target="_blank"
                    rel="noopener"
                  >
                    <TwitterIcon />
                  </SocialLink>
                  <SocialLink
                    href={settings.urls.github}
                    target="_blank"
                    rel="noopener"
                  >
                    <GitHubIcon />
                  </SocialLink>
                  <SocialLink
                    href={settings.urls.figma}
                    target="_blank"
                    rel="noopener"
                  >
                    <FigmaIcon />
                  </SocialLink>
                  <SocialLink
                    href={settings.urls.fey}
                    target="_blank"
                    rel="noopener"
                  >
                    <FeyIcon />
                  </SocialLink>
                </SocialContainer>
              </Desktop>
            </Column>
          </Container>
        </ContactSection>
      </SlideIn>
    </Frame>
  )
}

export default ContactSlideIn

const PasswordContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 24px;
  bottom: -3px;
  transition: opacity 0.2s var(--ease-out-quad),
    transform 0.2s var(--ease-out-quad);
`

const PasswordInput = styled.input`
  background: transparent;
  border: none;
  font-size: 18px;
  color: #fff;
  caret-color: ${p => p.theme.colors.purple};
  line-height: 1.6;

  ::placeholder {
    color: rgba(255, 255, 255, 0.2);
  }
`
const SocialDivider = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.15);
  margin-bottom: 25px;
`

const SocialLink = styled.a`
  width: 24px;
  height: 24px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  transition: background 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

const ContactContainer = styled.div`
  padding-top: 20vh;

  ${media.tablet`
    padding: 0 0 10vh;
  `}
`

const HeadingSmall = styled.h3`
  font-size: 18px;
  line-height: 23px;
  color: ${p => p.theme.colors.moon};
  margin-bottom: 2px;
  font-weight: 400;
`

const ResourceContainer = styled.div`
  position: relative;

  ${media.tablet`
    display: none;
  `}
`

const ResourceLink = styled.button`
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 30px;
  color: ${p => p.theme.colors.sirius};
  display: flex;
  align-items: flex-end;

  svg {
    margin-left: 8px;
  }

  &:hover {
    text-decoration: underline;
  }
`

const CopyIconContainer = styled.div`
  position: absolute;
  left: -28px;
  opacity: 0;
  transition: opacity 0.3s ease;
`

const CopyTextContainer = styled.div`
  position: absolute;
  top: 0;
  right: -56px;
  opacity: 0;
  transition: opacity 0.3s ease;
  color: #46b17b;
  font-weight: 400;
  font-size: 16px;
`

const ContactListEmail = styled.button`
  position: relative;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 30px;
  color: ${p => p.theme.colors.sirius};

  &:hover {
    text-decoration: underline;
  }

  &:hover ${CopyIconContainer} {
    opacity: 1;
  }
`

const ContactSection = styled(Section)`
  height: 100%;
`

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;

  ${media.tablet`
    flex-direction: column;
    align-items: center;
    text-align: center;
  `}
`
const Column = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const LogoContainer = styled.button`
  opacity: 0.24;
  transition: opacity 0.3s ease;
  width: 24px;

  &:hover {
    opacity: 0.4;
  }

  ${media.tablet`
    display: none;
  `}
`

const Heading = styled.h3`
  font-size: 70px;
  line-height: 105%;
  letter-spacing: -0.5px;
  font-family: ${p => p.theme.fontfamily.serif};

  ${media.tablet`
    font-size: 42px;
    padding: 0 0 16vh;
  `}

  ${media.phone_small`
    font-size: 42px;
    padding: 8vh 0 8vh;
  `}
`
const defaultStyle = {
  transform: 'translateY(100vh)',
}

const transitionStyles = {
  entering: { opacity: 0, transform: 'translateY(100vh)' },
  entered: { opacity: 1, transform: 'translateY(40px)' },
  exiting: { opacity: 1, transform: 'translateY(40px)' },
  exited: { opacity: 1, transform: 'translateY(100vh)' },
}

const SlideIn = ({ in: inProp, children }) => (
  <Transition in={inProp} timeout={0}>
    {state => (
      <SlideInContainer
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {children}
      </SlideInContainer>
    )}
  </Transition>
)

const Frame = styled.div<{ showContact: boolean }>`
  position: relative;
  z-index: 11;
  opacity: ${p => (p.showContact ? 1 : 0)};
  transition: opacity 0;
  ${p => !p.showContact && `transition-delay: 0.7s`}
`

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: rgb(29 33 40 / 80%);
  pointer-events: ${p => (p.isActive ? 'initial' : 'none')};
  opacity: ${p => (p.isActive ? 1 : 0)};
  transition: opacity ${p => (p.isActive ? '0.7s' : '0')}
    ${p => (p.isActive ? '0.3s' : '')};
`

const SlideInContainer = styled.div`
  width: 100%;
  height: calc(100vh - 40px);
  top: 0px;
  right: 0px;
  padding: 120px 0;
  z-index: 9;
  position: fixed;
  overflow-y: scroll;
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
  will-change: transform;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #000;
  backface-visibility: hidden;

  ${media.tablet`
    padding: 54px 0 66px;

    &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: calc(100vw - 40px);
    height: 1px;
    background: radial-gradient(
      174px at 50.14% 100.05%,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.02) 100%
    );
  }
  `}
`

const CloseContainer = styled.button`
  position: absolute;
  z-index: 1000;
  right: 32px;
  top: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg:first-of-type {
    margin-right: 12px;
  }

  ${media.tablet`

  right: 24px;
  top: 24px;

    svg:first-of-type {
      display: none;
    }
  `};

  &:hover {
    svg:first-of-type rect {
      transition: fill-opacity 0.2s ease;
      fill-opacity: 0.15;
    }
  }
`

const Desktop = styled.div`
  ${media.tablet`
    display: none;
  `}
`

const ChevronRight = ({ fill = theme.colors.moon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill={fill} />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
)

const CopyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0521 19.2396H11.979C11.6264 19.2396 11.3406 18.9537 11.3406 18.6012C11.3406 18.2486 11.6264 17.9627 11.979 17.9627H16.0521C16.2717 17.9628 16.4508 17.7866 16.4543 17.5669V10.2188C16.4541 10.1138 16.4129 10.013 16.3394 9.93793L14.3539 7.95247C14.2793 7.87836 14.1782 7.83702 14.073 7.83756H10.4724C10.2527 7.84104 10.0765 8.0201 10.0766 8.23975V18.6139C10.0766 18.9665 9.79073 19.2523 9.43815 19.2523C9.08556 19.2523 8.79974 18.9665 8.79974 18.6139V8.23975C8.80325 7.31495 9.55395 6.56711 10.4788 6.56712H14.0794C14.525 6.5656 14.9527 6.74264 15.2668 7.0587L17.2523 9.04415C17.5666 9.3595 17.7433 9.78639 17.7439 10.2316V17.5797C17.7333 18.5045 16.9769 19.2467 16.0521 19.2396ZM7.51653 16.0468V5.67264C7.52002 5.453 7.69907 5.27679 7.91873 5.27682H12.1578C12.5104 5.27682 12.7962 4.99099 12.7962 4.63841C12.7962 4.28583 12.5104 4 12.1578 4H7.91873C6.99393 4.00352 6.24609 4.75421 6.24609 5.67902V16.0532C6.24609 16.4058 6.53192 16.6916 6.8845 16.6916C7.23709 16.6916 7.52291 16.4058 7.52291 16.0532L7.51653 16.0468Z"
      fill="#FAFAFA"
    />
  </svg>
)

const FigIcon = () => (
  <svg
    width="42"
    height="26"
    viewBox="0 0 42 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="7"
      width="35"
      height="18"
      rx="3"
      fill="#73737D"
      fillOpacity="0.25"
    />
    <g filter="url(#filter0_d)">
      <path
        d="M37 6H36.5V5C36.5 3.62 35.38 2.5 34 2.5C32.62 2.5 31.5 3.62 31.5 5V6H31C30.45 6 30 6.45 30 7V12C30 12.55 30.45 13 31 13H37C37.55 13 38 12.55 38 12V7C38 6.45 37.55 6 37 6ZM35.55 6H32.45V5C32.45 4.145 33.145 3.45 34 3.45C34.855 3.45 35.55 4.145 35.55 5V6Z"
        fill="#73737D"
      />
    </g>
    <path
      d="M10.7803 20V17.228H13.9866V15.4907H10.7803V13.9323H14.2677V12.1566H8.74922V20H10.7803ZM17.2928 12.1566H15.2361V20H17.2928V12.1566ZM23.5257 17.343C23.3341 17.9434 22.8231 18.3266 22.0439 18.3266C20.9197 18.3266 20.2299 17.4196 20.2299 16.0783C20.2299 14.7754 20.8814 13.8301 22.0183 13.8301C22.7592 13.8301 23.1808 14.1877 23.3979 14.7881H25.4929C25.1991 13.1914 24.0366 12.0289 22.0183 12.0289C19.7701 12.0289 18.1605 13.6895 18.1605 16.0783C18.1605 18.4671 19.7701 20.1277 22.0439 20.1277C24.1261 20.1277 25.5568 18.7609 25.5568 16.5893V15.8101H22.3121V17.343H23.5257Z"
      fill="#FAFAFA"
    />
    <defs>
      <filter
        id="filter0_d"
        x="28"
        y="1.5"
        width="12"
        height="14.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

const EscIcon = () => (
  <svg
    width="40"
    height="17"
    viewBox="0 0 40 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="17" rx="3" fill="white" fillOpacity="0.1" />
    <path
      d="M15.6219 10.1382C15.4539 10.8382 14.8799 11.2302 14.1099 11.2302C13.0599 11.2302 12.3179 10.4182 12.3039 9.0882H16.6999V8.7102C16.6999 6.9042 15.7899 5.6582 14.0819 5.6582C12.3879 5.6582 11.2539 7.0442 11.2539 8.9062C11.2539 10.8242 12.4159 12.1262 14.1099 12.1262C15.5099 12.1262 16.3919 11.3142 16.6159 10.1382H15.6219ZM14.0959 6.5542C15.1319 6.5542 15.6219 7.3662 15.6359 8.2902H12.3319C12.4859 7.1982 13.1579 6.5542 14.0959 6.5542Z"
      fill="#FAFAFA"
    />
    <path
      d="M17.6097 10.2922C17.8057 11.4402 18.7997 12.1262 20.1577 12.1262C21.4877 12.1262 22.4677 11.4262 22.4677 10.2502C22.4677 9.3822 21.9497 8.7942 20.8857 8.5142L19.6817 8.2202C19.0937 8.0522 18.8137 7.8562 18.8137 7.4362C18.8137 6.8342 19.3877 6.5402 20.0037 6.5402C20.7597 6.5402 21.1937 6.8622 21.3757 7.4222H22.4257C22.2017 6.3302 21.3897 5.6582 20.0177 5.6582C18.7577 5.6582 17.7917 6.3862 17.7917 7.4922C17.7917 8.4582 18.4077 8.9062 19.2897 9.1302L20.5217 9.4522C21.1657 9.6202 21.4457 9.8862 21.4457 10.3202C21.4457 10.9502 20.9137 11.2442 20.1717 11.2442C19.4437 11.2442 18.8977 10.9362 18.7017 10.2922H17.6097Z"
      fill="#FAFAFA"
    />
    <path
      d="M23.4766 8.8922C23.4766 10.8242 24.6386 12.1262 26.3046 12.1262C27.7046 12.1262 28.5866 11.3142 28.7966 10.1242H27.7186C27.5786 10.7962 27.0466 11.2022 26.3046 11.2022C25.2406 11.2022 24.5406 10.2502 24.5406 8.8922C24.5406 7.5342 25.2406 6.5822 26.3046 6.5822C27.0466 6.5822 27.5786 6.9882 27.7186 7.6602H28.7966C28.5866 6.4702 27.7046 5.6582 26.3046 5.6582C24.6386 5.6582 23.4766 6.9602 23.4766 8.8922Z"
      fill="#FAFAFA"
    />
  </svg>
)

const TwitterIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8176 8.23793C18.4221 7.87561 18.8862 7.30193 19.1048 6.61838C18.5392 6.95382 17.9127 7.19744 17.2459 7.32876C16.7119 6.75982 15.9511 6.4043 15.1091 6.4043C13.4924 6.4043 12.1817 7.71497 12.1817 9.33159C12.1817 9.56102 12.2076 9.78445 12.2575 9.99869C9.82459 9.87662 7.66758 8.71119 6.22377 6.94014C5.97179 7.37249 5.82743 7.87535 5.82743 8.41183C5.82743 9.42741 6.34428 10.3234 7.12975 10.8484C6.64986 10.8332 6.19851 10.7015 5.80379 10.4822C5.80358 10.4945 5.80358 10.5067 5.80358 10.519C5.80358 11.9374 6.81264 13.1206 8.15181 13.3894C7.90615 13.4564 7.64754 13.4921 7.38053 13.4921C7.19191 13.4921 7.00851 13.4738 6.82981 13.4396C7.2023 14.6026 8.28338 15.449 9.56436 15.4726C8.5625 16.2577 7.30026 16.7257 5.92878 16.7257C5.69252 16.7257 5.45948 16.7119 5.23047 16.6848C6.52595 17.5154 8.0647 18 9.71785 18C15.1023 18 18.0467 13.5394 18.0467 9.67104C18.0467 9.54411 18.0439 9.41786 18.0383 9.29234C18.6102 8.87961 19.1065 8.36402 19.499 7.77697C18.974 8.0098 18.4098 8.16715 17.8176 8.23793Z"
      fill="#FAFAFA"
    />
  </svg>
)

const GitHubIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1771 5C8.21173 5 5 8.21173 5 12.1771C5 15.3529 7.05443 18.0353 9.90731 18.9863C10.2662 19.0491 10.4007 18.8338 10.4007 18.6454C10.4007 18.4749 10.3918 17.9097 10.3918 17.3087C8.58853 17.6406 8.12202 16.8691 7.97848 16.4653C7.89774 16.259 7.54786 15.622 7.24283 15.4516C6.99163 15.317 6.63278 14.9851 7.23386 14.9761C7.79905 14.9671 8.20276 15.4964 8.33733 15.7118C8.98327 16.7973 10.015 16.4923 10.4276 16.3039C10.4904 15.8374 10.6788 15.5234 10.8852 15.3439C9.28829 15.1645 7.61963 14.5455 7.61963 11.8003C7.61963 11.0198 7.89774 10.3738 8.35527 9.87143C8.2835 9.692 8.03231 8.95635 8.42704 7.96951C8.42704 7.96951 9.02812 7.78111 10.4007 8.70516C10.9749 8.54367 11.5849 8.46293 12.195 8.46293C12.805 8.46293 13.4151 8.54367 13.9893 8.70516C15.3619 7.77214 15.963 7.96951 15.963 7.96951C16.3577 8.95635 16.1065 9.692 16.0347 9.87143C16.4923 10.3738 16.7704 11.0108 16.7704 11.8003C16.7704 14.5545 15.0927 15.1645 13.4958 15.3439C13.756 15.5682 13.9803 15.9988 13.9803 16.6717C13.9803 17.6316 13.9713 18.4032 13.9713 18.6454C13.9713 18.8338 14.1059 19.0581 14.4647 18.9863C17.2997 18.0353 19.3541 15.3439 19.3541 12.1771C19.3541 8.21173 16.1424 5 12.1771 5Z"
      fill="#FAFAFA"
    />
  </svg>
)

const FigmaIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.43005 8.31491C8.43005 7.46868 9.11711 6.78162 9.96335 6.78162H11.4966V9.84821H9.96335C9.11711 9.84821 8.43005 9.16115 8.43005 8.31491ZM9.96335 5.77539C8.56139 5.77539 7.42383 6.91295 7.42383 8.31491C7.42383 9.14828 7.82579 9.88823 8.4463 10.3513C7.82579 10.8145 7.42383 11.5544 7.42383 12.3878C7.42383 13.2211 7.82574 13.961 8.44618 14.4241C7.82574 14.8873 7.42383 15.6272 7.42383 16.4605C7.42383 17.8624 8.56139 19 9.96335 19C11.3653 19 12.5029 17.8624 12.5029 16.4605V14.9273V14.4242V14.4241V14.4118C12.929 14.7353 13.4604 14.9273 14.0364 14.9273C15.4384 14.9273 16.5759 13.7897 16.5759 12.3878C16.5759 11.5544 16.174 10.8145 15.5534 10.3513C16.174 9.88823 16.5759 9.14828 16.5759 8.31491C16.5759 6.91295 15.4384 5.77539 14.0364 5.77539H12.5029H12H11.9998H11.4969H9.96335ZM9.96335 10.8545C9.11711 10.8545 8.43005 11.5415 8.43005 12.3878C8.43005 13.2289 9.10876 13.9127 9.94788 13.921L9.96335 13.921H11.4966V10.8545H9.96335ZM8.43005 16.4605C8.43005 15.6194 9.10876 14.9356 9.94788 14.9273L9.96335 14.9273H11.4966V16.4605C11.4966 17.3067 10.8096 17.9938 9.96335 17.9938C9.11711 17.9938 8.43005 17.3067 8.43005 16.4605ZM12.5031 9.84821H14.0364C14.8826 9.84821 15.5697 9.16115 15.5697 8.31491C15.5697 7.46868 14.8826 6.78162 14.0364 6.78162H12.5031V9.84821ZM12.5031 12.3878C12.5031 11.5415 13.1902 10.8545 14.0364 10.8545C14.8826 10.8545 15.5697 11.5415 15.5697 12.3878C15.5697 13.234 14.8826 13.9211 14.0364 13.9211C13.1902 13.9211 12.5031 13.234 12.5031 12.3878Z"
      fill="#FAFAFA"
    />
  </svg>
)

const FeyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3874 16.3563C14.5046 16.4712 14.5977 16.6082 14.6613 16.7595C14.7249 16.9108 14.7576 17.0732 14.7576 17.2373C14.7576 17.4014 14.7249 17.5638 14.6613 17.7151C14.5977 17.8664 14.5046 18.0034 14.3874 18.1183L13.5382 18.9658L12.6748 18.1006C12.201 17.6257 11.9205 16.9917 11.888 16.3216C11.8554 15.6515 12.0731 14.9932 12.4986 14.4746L14.3874 16.3563Z"
      fill="white"
    />
    <path
      d="M14.4096 10.079C14.6356 10.3053 14.7625 10.612 14.7625 10.9318C14.7625 11.2515 14.6356 11.5582 14.4096 11.7845L12.2953 13.8988C11.9935 14.1988 11.7542 14.5558 11.5915 14.949C11.4288 15.3422 11.3458 15.7638 11.3474 16.1893C11.3479 16.4113 11.371 16.6327 11.4161 16.8501L10.5827 15.9938C10.0758 15.486 9.79102 14.7978 9.79102 14.0803C9.79102 13.3628 10.0758 12.6746 10.5827 12.1669L13.5463 9.20508L14.4096 10.079Z"
      fill="white"
    />
    <path
      d="M14.4063 5.86863C14.633 6.09559 14.7604 6.40326 14.7604 6.72404C14.7604 7.04483 14.633 7.35249 14.4063 7.57945L10.2006 11.7904C9.81992 12.1707 9.53999 12.6398 9.38617 13.1554C9.23235 13.671 9.2095 14.2168 9.31967 14.7434L8.46867 13.8907C8.21732 13.6398 8.01792 13.3418 7.88186 13.0138C7.74581 12.6857 7.67578 12.3341 7.67578 11.979C7.67578 11.6238 7.74581 11.2722 7.88186 10.9442C8.01792 10.6161 8.21732 10.3182 8.46867 10.0673L13.536 5L14.4063 5.86863Z"
      fill="white"
    />
  </svg>
)
