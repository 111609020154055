import React, { Component } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Link, navigate } from 'gatsby'
import Section from '@components/Section'
import Logo from '@components/Logo'

import media from '@styles/media'

import shortcuts, { constants } from '@shortcuts'

const navLinks = [
  { to: '/about', text: 'About' },
  { to: '/products', text: 'Products' },
  { to: '/articles', text: 'Articles' },
  { to: '/contact', text: 'Contact' },
]

const themes = {
  light: {
    color: '#fff',
    pseudo: 'transparent',
    symbol: {
      color: '#000',
      background: 'rgba(255,255,255,0.3)',
    },
  },
  dark: {
    color: '#000',
    pseudo: '#fafafa',
    symbol: {
      color: '#1D2128',
      background: '#dbdbdc',
    },
  },
}

interface NavigationState {
  active: boolean
  previousPath: string
  showPreviousPath: boolean
  linkBackToHome: boolean
  hideNavItems: boolean
}

class Navigation extends Component<{}, NavigationState> {
  leftToggle = React.createRef()

  state = {
    active: false,
    previousPath: '',
    showPreviousPath: false,
    linkBackToHome: false,
    hideNavItems: false,
  }

  componentDidMount() {
    const previousPath = localStorage.getItem('previousPath')
    const urlsThatUseBackButton = ['/products/']

    this.setState({
      previousPath,
      linkBackToHome: urlsThatUseBackButton.some(
        pathname => window.location.pathname.indexOf(pathname) >= 0
      ),
      hideNavItems: window.location.pathname.split('/').includes('novela'),
    })
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (typeof window !== 'undefined') {
      const previousPathFromStorage = localStorage.getItem('previousPath')
      const urlsThatUseBackButton = ['/articles/']

      if (prevState.previousPath !== previousPathFromStorage) {
        this.setState({
          previousPath: previousPathFromStorage,
          showPreviousPath: urlsThatUseBackButton.some(
            pathname => window.location.pathname.indexOf(pathname) >= 0
          ),
        })
      }
    }
    return null
  }

  handleShortcutReset = () => {
    shortcuts.handleShortcutFeature({ name: constants.ESCAPE })
  }

  render() {
    const {
      active,
      previousPath,
      showPreviousPath,
      linkBackToHome,
      hideNavItems,
    } = this.state
    const { nav } = this.props
    const fill = nav.theme === 'dark' ? '#000' : '#fff'
    const theme = themes[nav.theme]

    return (
      <ThemeProvider theme={theme}>
        <NavFixedContainer navFixed={nav.fixed}>
          <Section>
            <NavContainer>
              {previousPath && showPreviousPath && (
                <LogoBack
                  onClick={() => window.history.back()}
                  data-a11y="false"
                >
                  <BackChevron />
                </LogoBack>
              )}
              {linkBackToHome && !showPreviousPath && (
                <LogoBack onClick={() => navigate('/')} data-a11y="false">
                  <BackChevron fill={fill} />
                </LogoBack>
              )}
              <LogoMask>
                <LogoContainer
                  to="/"
                  onClick={this.handleShortcutReset}
                  aria-label="Back home"
                  data-a11y="false"
                >
                  <Logo fill={fill} />
                </LogoContainer>
              </LogoMask>
              <Right>
                {hideNavItems ? null : (
                  <nav>
                    <DesktopNavList id="menu-list">
                      <NavItems active={active} />
                    </DesktopNavList>
                  </nav>
                )}
              </Right>
            </NavContainer>
          </Section>
        </NavFixedContainer>
      </ThemeProvider>
    )
  }
}

export default Navigation

const NavItems = ({ active }) => {
  return navLinks.map(nav => {
    if (nav.to === '/contact') {
      return (
        <NavAnchor
          active={active ? active : undefined}
          disabled={nav.disabled}
          to={nav.to}
          key={nav.to}
          tabIndex={active ? 0 : -1}
          data-a11y="false"
          onClick={event => {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'c' }))
          }}
          getProps={({ isPartiallyCurrent }) =>
            isPartiallyCurrent ? { ['data-active']: 'true' } : null
          }
        >
          {nav.text}
        </NavAnchor>
      )
    }

    return (
      <NavAnchor
        active={active ? active : undefined}
        disabled={nav.disabled}
        to={nav.to}
        key={nav.to}
        tabIndex={active ? 0 : -1}
        data-a11y="false"
        getProps={({ isPartiallyCurrent }) =>
          isPartiallyCurrent ? { ['data-active']: 'true' } : null
        }
      >
        {nav.text}
      </NavAnchor>
    )
  })
}

const NavFixedContainer = styled.div`
  position: absolute;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;

  ${media.tablet`
    position: ${p => (p.navFixed ? 'fixed' : 'absolute')};
  `}
`

const NavContainer = styled.div`
  position: relative;
  z-index: 100;
  padding-top: 100px;
  display: flex;
  justify-content: space-between;

  ${media.desktop_medium`
    padding-top: 50px;
  `};

  @media screen and (max-height: 800px) {
    padding-top: 60px;
  }
`

const LogoBack = styled.button`
  position: absolute;
  left: -44px;

  svg {
    transition: transform 0.25s var(--ease-out-quad);
  }

  &:hover svg,
  &:focus svg {
    transform: translateX(-4px);
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: 0;
    top: 1px;
    width: 100%;
    height: 100%;
    border: 2px solid ${p => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${media.desktop_medium`
    top: 57px;
    left: -34px;
  `}

  @media screen and (max-height: 800px) {
    top: 67px;
  }

  ${media.tablet`
    display: none;
  `}
`

const LogoContainer = styled(Link)`
  position: relative;
  transition: opacity 0.3s var(--ease-out-quad);
  max-width: 114px;

  &:hover {
    opacity: 0.6;
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -10%;
    top: -42%;
    width: 120%;
    height: 200%;
    border: 2px solid ${p => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }
`

const LogoMask = styled.div`
  display: inline-block;
  max-width: 114px;

  ${media.tablet`
    overflow: hidden;
  `}
`

const ToggleContainer = styled.button`
  position: relative;
  height: 40px;
  width: 40px;
  right: -10px;
  cursor: pointer;

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: 0;
    top: 1px;
    width: 100%;
    height: 100%;
    border: 2px solid ${p => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${media.phablet`
    width: 30px;
    height: 30px;
    right: -10px;
  `};
`

const Toggle = styled.span`
  position: absolute;
  right: 10px;
  height: 1px;
  background: ${p => p.theme.color};
  transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  ${media.tablet`
      display: none;
    `}
`

const LeftToggle = styled(Toggle)`
  top: 23px;
  width: ${p => (p.active ? '20px' : '15px')};

  ${media.phablet`
    top: 15px;
    width: 15px;
  `};

  &::after {
    content: '';
    position: absolute;
    height: 1px;
    background: ${p => p.theme.pseudo};
    width: 100%;
    bottom: -1px;
    left: 0;
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`

const DesktopNavList = styled.ul`
  list-style: none;

  ${media.tablet`
    display: none;
  `};
`

const NavItem = styled.li``

const NavAnchor = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  color: ${p => p.theme.color};
  font-weight: 600;
  font-size: 18px;
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.9);

  display: inline-block;
  margin-right: 50px;

  &:last-child {
    margin-right: 0;
  }

  &[data-active='true'] {
    &::after {
      content: '';
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: -4px;
      height: 1px;
      width: 20px;
      background: ${p => p.theme.color};
    }
  }

  &:hover {
    opacity: ${p => (p.disabled ? 0.15 : 0.6)};
  }

  &:focus {
    outline: none;
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -25%;
    top: 2%;
    width: 150%;
    height: 100%;
    border: 2px solid ${p => p.theme.colors.purple};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${media.phablet`
    display: none;
  `};
`

const BackChevron = ({ fill = 'black' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.41 16.09L10.83 11.5L15.41 6.91L14 5.5L8 11.5L14 17.5L15.41 16.09Z"
      fill={fill}
    />
  </svg>
)
