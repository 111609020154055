import React from 'react'

export const DribbbleIcon = ({ fill = '#FAFAFA' }) => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.23047 0C3.37063 0 0.230469 3.14019 0.230469 7C0.230469 10.8598 3.37063 14 7.23047 14C11.0903 14 14.2305 10.8598 14.2305 7C14.2305 3.14019 11.0903 0 7.23047 0ZM11.7921 3.30136C12.5779 4.26869 13.0617 5.48966 13.102 6.82062C11.6463 6.51926 10.3281 6.55462 9.16451 6.78278C8.95381 6.29005 8.7357 5.82062 8.51556 5.37697C9.78282 4.83104 10.8861 4.13375 11.7921 3.30136ZM11.0035 2.49993C10.1042 3.32085 9.06193 3.92867 7.99473 4.37742C7.21144 2.945 6.46201 1.86633 6.00547 1.25333C6.40079 1.16911 6.81042 1.12393 7.23047 1.12393C8.66586 1.12393 9.98198 1.64199 11.0035 2.49993ZM4.87031 1.62041C5.21505 2.06714 6.03619 3.18567 6.91824 4.77709C4.70142 5.49933 2.55039 5.62101 1.51676 5.63232C1.94759 3.83253 3.20796 2.35262 4.87031 1.62041ZM1.36053 6.7591C1.37162 6.7591 1.38234 6.75918 1.39365 6.75918C2.10787 6.75918 3.41641 6.71115 4.95693 6.43024C5.83583 6.26997 6.66926 6.05425 7.45128 5.7869C7.65486 6.19264 7.85724 6.62064 8.05348 7.06923C6.95848 7.42177 6.03507 7.92904 5.30362 8.43271C4.07705 9.27739 3.21777 10.2145 2.74857 10.7939C1.87986 9.76944 1.35439 8.4453 1.35439 7C1.35439 6.91923 1.35724 6.83913 1.36053 6.7591ZM3.55847 11.5826C3.96241 11.074 4.78459 10.1482 5.97393 9.33591C6.78217 8.78383 7.61837 8.37367 8.47922 8.10445C8.97869 9.39907 9.4051 10.8295 9.65934 12.3484C8.91845 12.6862 8.09649 12.8761 7.23047 12.8761C5.8419 12.8761 4.5652 12.3909 3.55847 11.5826ZM10.6933 11.7432C10.4313 10.3483 10.0406 9.03664 9.58883 7.83793C10.7 7.65046 11.8484 7.68912 13.0279 7.95407C12.7736 9.50435 11.9109 10.8519 10.6933 11.7432Z"
      fill={fill}
    />
  </svg>
)

export const InstagramIcon = ({ fill = '#FAFAFA' }) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRle="evenodd"
      clipRle="evenodd"
      d="M-3.05176e-05 3.97163C-3.05176e-05 1.77803 1.77824 -0.000244141 3.97184 -0.000244141H9.0281C11.2217 -0.000244141 13 1.77802 13 3.97163V9.02788C13 11.2215 11.2217 12.9998 9.0281 12.9998H3.97184C1.77824 12.9998 -3.05176e-05 11.2215 -3.05176e-05 9.02789V3.97163ZM3.97184 1.281C2.48585 1.281 1.28122 2.48564 1.28122 3.97163V9.02789C1.28122 10.5139 2.48585 11.7185 3.97184 11.7185H9.0281C10.5141 11.7185 11.7187 10.5139 11.7187 9.02788V3.97163C11.7187 2.48564 10.5141 1.281 9.0281 1.281H3.97184Z"
      fill={fill}
    />
    <path
      fillRle="evenodd"
      clipRle="evenodd"
      d="M3.07483 6.55115C3.07483 4.64454 4.61242 3.09253 6.51702 3.09253C8.42162 3.09253 9.95921 4.64454 9.95921 6.55115C9.95921 8.45776 8.42162 10.0098 6.51702 10.0098C4.61242 10.0098 3.07483 8.45776 3.07483 6.55115ZM6.51702 4.37378C5.32709 4.37378 4.35608 5.34508 4.35608 6.55115C4.35608 7.75722 5.32709 8.72853 6.51702 8.72853C7.70695 8.72853 8.67796 7.75722 8.67796 6.55115C8.67796 5.34508 7.70695 4.37378 6.51702 4.37378Z"
      fill={fill}
    />
    <path
      fillRle="evenodd"
      clipRle="evenodd"
      d="M9.95062 3.87075C10.4035 3.87075 10.7706 3.50149 10.7706 3.04597C10.7706 2.59046 10.4035 2.22119 9.95062 2.22119C9.49776 2.22119 9.13065 2.59046 9.13065 3.04597C9.13065 3.50149 9.49776 3.87075 9.95062 3.87075Z"
      fill={fill}
    />
  </svg>
)

export const LinkedInIcon = ({ fill = '#FAFAFA' }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRle="evenodd"
      clipRle="evenodd"
      d="M3.59615 13.125H0.871552V4.36523H3.59615V13.125ZM2.24847 3.16406C1.81878 3.16406 1.44769 3.00781 1.13519 2.69531C0.822692 2.38281 0.666443 2.01171 0.666443 1.58203C0.666443 1.15234 0.822692 0.781248 1.13519 0.468749C1.44769 0.156249 1.81878 0 2.24847 0C2.67816 0 3.04925 0.156249 3.36175 0.468749C3.67425 0.781248 3.8305 1.15234 3.8305 1.58203C3.8305 2.01171 3.67425 2.38281 3.36175 2.69531C3.04925 3.00781 2.67816 3.16406 2.24847 3.16406ZM13.7915 13.125H11.0669V8.84765C11.0669 8.14452 11.0083 7.63671 10.8911 7.32421C10.6763 6.79687 10.2563 6.5332 9.63134 6.5332C9.00634 6.5332 8.56689 6.76757 8.31298 7.23632C8.11767 7.58788 8.02001 8.10546 8.02001 8.78905V13.125H5.32471V4.36523H7.93212V5.5664H7.96142C8.15673 5.17578 8.46923 4.85351 8.89892 4.59961C9.36767 4.28711 9.91454 4.13086 10.5395 4.13086C11.8091 4.13086 12.6977 4.53125 13.2055 5.33203C13.5962 5.97656 13.7915 6.97265 13.7915 8.3203V13.125Z"
      fill={fill}
    />
  </svg>
)

export const GithubIcon = ({ fill = '#FAFAFA' }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="15"
    height="15"
    viewBox="0 0 438.549 438.549"
  >
    <path
      fill={fill}
      d="M409.132,114.573c-19.608-33.596-46.205-60.194-79.798-79.8C295.736,15.166,259.057,5.365,219.271,5.365
   c-39.781,0-76.472,9.804-110.063,29.408c-33.596,19.605-60.192,46.204-79.8,79.8C9.803,148.168,0,184.854,0,224.63
   c0,47.78,13.94,90.745,41.827,128.906c27.884,38.164,63.906,64.572,108.063,79.227c5.14,0.954,8.945,0.283,11.419-1.996
   c2.475-2.282,3.711-5.14,3.711-8.562c0-0.571-0.049-5.708-0.144-15.417c-0.098-9.709-0.144-18.179-0.144-25.406l-6.567,1.136
   c-4.187,0.767-9.469,1.092-15.846,1c-6.374-0.089-12.991-0.757-19.842-1.999c-6.854-1.231-13.229-4.086-19.13-8.559
   c-5.898-4.473-10.085-10.328-12.56-17.556l-2.855-6.57c-1.903-4.374-4.899-9.233-8.992-14.559
   c-4.093-5.331-8.232-8.945-12.419-10.848l-1.999-1.431c-1.332-0.951-2.568-2.098-3.711-3.429c-1.142-1.331-1.997-2.663-2.568-3.997
   c-0.572-1.335-0.098-2.43,1.427-3.289c1.525-0.859,4.281-1.276,8.28-1.276l5.708,0.853c3.807,0.763,8.516,3.042,14.133,6.851
   c5.614,3.806,10.229,8.754,13.846,14.842c4.38,7.806,9.657,13.754,15.846,17.847c6.184,4.093,12.419,6.136,18.699,6.136
   c6.28,0,11.704-0.476,16.274-1.423c4.565-0.952,8.848-2.383,12.847-4.285c1.713-12.758,6.377-22.559,13.988-29.41
   c-10.848-1.14-20.601-2.857-29.264-5.14c-8.658-2.286-17.605-5.996-26.835-11.14c-9.235-5.137-16.896-11.516-22.985-19.126
   c-6.09-7.614-11.088-17.61-14.987-29.979c-3.901-12.374-5.852-26.648-5.852-42.826c0-23.035,7.52-42.637,22.557-58.817
   c-7.044-17.318-6.379-36.732,1.997-58.24c5.52-1.715,13.706-0.428,24.554,3.853c10.85,4.283,18.794,7.952,23.84,10.994
   c5.046,3.041,9.089,5.618,12.135,7.708c17.705-4.947,35.976-7.421,54.818-7.421s37.117,2.474,54.823,7.421l10.849-6.849
   c7.419-4.57,16.18-8.758,26.262-12.565c10.088-3.805,17.802-4.853,23.134-3.138c8.562,21.509,9.325,40.922,2.279,58.24
   c15.036,16.18,22.559,35.787,22.559,58.817c0,16.178-1.958,30.497-5.853,42.966c-3.9,12.471-8.941,22.457-15.125,29.979
   c-6.191,7.521-13.901,13.85-23.131,18.986c-9.232,5.14-18.182,8.85-26.84,11.136c-8.662,2.286-18.415,4.004-29.263,5.146
   c9.894,8.562,14.842,22.077,14.842,40.539v60.237c0,3.422,1.19,6.279,3.572,8.562c2.379,2.279,6.136,2.95,11.276,1.995
   c44.163-14.653,80.185-41.062,108.068-79.226c27.88-38.161,41.825-81.126,41.825-128.906
   C438.536,184.851,428.728,148.168,409.132,114.573z"
    />
  </svg>
)

export const TwitterIcon = ({ fill = '#FAFAFA' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8176 8.23793C18.4221 7.87561 18.8862 7.30193 19.1048 6.61838C18.5392 6.95382 17.9127 7.19744 17.2459 7.32876C16.7119 6.75982 15.9511 6.4043 15.1091 6.4043C13.4924 6.4043 12.1817 7.71497 12.1817 9.33159C12.1817 9.56102 12.2076 9.78445 12.2575 9.99869C9.82459 9.87662 7.66758 8.71119 6.22377 6.94014C5.97179 7.37249 5.82743 7.87535 5.82743 8.41183C5.82743 9.42741 6.34428 10.3234 7.12975 10.8484C6.64986 10.8332 6.19851 10.7015 5.80379 10.4822C5.80358 10.4945 5.80358 10.5067 5.80358 10.519C5.80358 11.9374 6.81264 13.1206 8.15181 13.3894C7.90615 13.4564 7.64754 13.4921 7.38053 13.4921C7.19191 13.4921 7.00851 13.4738 6.82981 13.4396C7.2023 14.6026 8.28338 15.449 9.56436 15.4726C8.5625 16.2577 7.30026 16.7257 5.92878 16.7257C5.69252 16.7257 5.45948 16.7119 5.23047 16.6848C6.52595 17.5154 8.0647 18 9.71785 18C15.1023 18 18.0467 13.5394 18.0467 9.67104C18.0467 9.54411 18.0439 9.41786 18.0383 9.29234C18.6102 8.87961 19.1065 8.36402 19.499 7.77697C18.974 8.0098 18.4098 8.16715 17.8176 8.23793Z"
      fill={fill}
    />
  </svg>
)

export const GitHubIcon = ({ fill = '#FAFAFA' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1771 5C8.21173 5 5 8.21173 5 12.1771C5 15.3529 7.05443 18.0353 9.90731 18.9863C10.2662 19.0491 10.4007 18.8338 10.4007 18.6454C10.4007 18.4749 10.3918 17.9097 10.3918 17.3087C8.58853 17.6406 8.12202 16.8691 7.97848 16.4653C7.89774 16.259 7.54786 15.622 7.24283 15.4516C6.99163 15.317 6.63278 14.9851 7.23386 14.9761C7.79905 14.9671 8.20276 15.4964 8.33733 15.7118C8.98327 16.7973 10.015 16.4923 10.4276 16.3039C10.4904 15.8374 10.6788 15.5234 10.8852 15.3439C9.28829 15.1645 7.61963 14.5455 7.61963 11.8003C7.61963 11.0198 7.89774 10.3738 8.35527 9.87143C8.2835 9.692 8.03231 8.95635 8.42704 7.96951C8.42704 7.96951 9.02812 7.78111 10.4007 8.70516C10.9749 8.54367 11.5849 8.46293 12.195 8.46293C12.805 8.46293 13.4151 8.54367 13.9893 8.70516C15.3619 7.77214 15.963 7.96951 15.963 7.96951C16.3577 8.95635 16.1065 9.692 16.0347 9.87143C16.4923 10.3738 16.7704 11.0108 16.7704 11.8003C16.7704 14.5545 15.0927 15.1645 13.4958 15.3439C13.756 15.5682 13.9803 15.9988 13.9803 16.6717C13.9803 17.6316 13.9713 18.4032 13.9713 18.6454C13.9713 18.8338 14.1059 19.0581 14.4647 18.9863C17.2997 18.0353 19.3541 15.3439 19.3541 12.1771C19.3541 8.21173 16.1424 5 12.1771 5Z"
      fill={fill}
    />
  </svg>
)

export const FigmaIcon = ({ fill = '#FAFAFA' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.43005 8.31491C8.43005 7.46868 9.11711 6.78162 9.96335 6.78162H11.4966V9.84821H9.96335C9.11711 9.84821 8.43005 9.16115 8.43005 8.31491ZM9.96335 5.77539C8.56139 5.77539 7.42383 6.91295 7.42383 8.31491C7.42383 9.14828 7.82579 9.88823 8.4463 10.3513C7.82579 10.8145 7.42383 11.5544 7.42383 12.3878C7.42383 13.2211 7.82574 13.961 8.44618 14.4241C7.82574 14.8873 7.42383 15.6272 7.42383 16.4605C7.42383 17.8624 8.56139 19 9.96335 19C11.3653 19 12.5029 17.8624 12.5029 16.4605V14.9273V14.4242V14.4241V14.4118C12.929 14.7353 13.4604 14.9273 14.0364 14.9273C15.4384 14.9273 16.5759 13.7897 16.5759 12.3878C16.5759 11.5544 16.174 10.8145 15.5534 10.3513C16.174 9.88823 16.5759 9.14828 16.5759 8.31491C16.5759 6.91295 15.4384 5.77539 14.0364 5.77539H12.5029H12H11.9998H11.4969H9.96335ZM9.96335 10.8545C9.11711 10.8545 8.43005 11.5415 8.43005 12.3878C8.43005 13.2289 9.10876 13.9127 9.94788 13.921L9.96335 13.921H11.4966V10.8545H9.96335ZM8.43005 16.4605C8.43005 15.6194 9.10876 14.9356 9.94788 14.9273L9.96335 14.9273H11.4966V16.4605C11.4966 17.3067 10.8096 17.9938 9.96335 17.9938C9.11711 17.9938 8.43005 17.3067 8.43005 16.4605ZM12.5031 9.84821H14.0364C14.8826 9.84821 15.5697 9.16115 15.5697 8.31491C15.5697 7.46868 14.8826 6.78162 14.0364 6.78162H12.5031V9.84821ZM12.5031 12.3878C12.5031 11.5415 13.1902 10.8545 14.0364 10.8545C14.8826 10.8545 15.5697 11.5415 15.5697 12.3878C15.5697 13.234 14.8826 13.9211 14.0364 13.9211C13.1902 13.9211 12.5031 13.234 12.5031 12.3878Z"
      fill={fill}
    />
  </svg>
)

export const FeyIcon = ({ fill = '#FAFAFA' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3874 16.3563C15.5046 16.4712 15.5977 16.6082 15.6613 16.7595C15.7249 16.9108 15.7576 17.0732 15.7576 17.2373C15.7576 17.4014 15.7249 17.5638 15.6613 17.7151C15.5977 17.8664 15.5046 18.0034 15.3874 18.1183L14.5382 18.9658L13.6748 18.1006C13.201 17.6257 12.9205 16.9917 12.888 16.3216C12.8554 15.6515 13.0731 14.9932 13.4986 14.4746L15.3874 16.3563Z"
      fill={fill}
    />
    <path
      d="M15.4096 10.079C15.6356 10.3053 15.7625 10.612 15.7625 10.9318C15.7625 11.2515 15.6356 11.5582 15.4096 11.7845L13.2953 13.8988C12.9935 14.1988 12.7542 14.5558 12.5915 14.949C12.4288 15.3422 12.3458 15.7638 12.3474 16.1893C12.3479 16.4113 12.371 16.6327 12.4161 16.8501L11.5827 15.9938C11.0758 15.486 10.791 14.7978 10.791 14.0803C10.791 13.3628 11.0758 12.6746 11.5827 12.1669L14.5463 9.20508L15.4096 10.079Z"
      fill={fill}
    />
    <path
      d="M15.4063 5.86863C15.633 6.09559 15.7604 6.40326 15.7604 6.72404C15.7604 7.04483 15.633 7.35249 15.4063 7.57945L11.2006 11.7904C10.8199 12.1707 10.54 12.6398 10.3862 13.1554C10.2324 13.671 10.2095 14.2168 10.3197 14.7434L9.46867 13.8907C9.21732 13.6398 9.01792 13.3418 8.88186 13.0138C8.74581 12.6857 8.67578 12.3341 8.67578 11.979C8.67578 11.6238 8.74581 11.2722 8.88186 10.9442C9.01792 10.6161 9.21732 10.3182 9.46867 10.0673L14.536 5L15.4063 5.86863Z"
      fill={fill}
    />
  </svg>
)
