/**
 * Huge shoutout to Storybook as the core architecture of our shortcuts
 * is based off of their source. Storybook is a development environment
 * for UI components. It allows you to browse a component library, view
 * the different states of each component, and interactively develop and
 * test components.
 *
 * https://github.com/storybooks/storybook
 */

import * as icons from '../icons/ui'
import * as constants from './constants'
import { controlOrMetaKey } from './utils'

/**
 * All Narative shortcuts are defined here. This is the master document
 * that controls what keys are listened for on the keydown event listener
 */
const shortcuts = [
  {
    name: constants.COMMAND_LINE_DEFAULT,
    keys: [controlOrMetaKey(), 'K'],
    label: ['Open ', 'Command Line'],
    icon: icons.GoToIcon,
  },
  {
    name: constants.CONTACT,
    keys: ['C'],
    label: ['', 'Contact us'],
    icon: icons.MailIcon,
  },
  {
    name: constants.COMMAND_LINE_READ,
    keys: ['shift', 'A'],
    label: ['Read', 'Articles'],
    icon: icons.BookIcon,
  },
  {
    name: constants.COMMAND_LINE_OPEN_SOURCE,
    keys: ['shift', 'O'],
    label: ['View', 'Open Source'],
    icon: icons.OpenSourceIcon,
  },
  {
    name: constants.GO_TO_ABOUT,
    keys: ['G', 'A'],
    label: ['Go to', ' About'],
    icon: icons.AboutIcon,
  },
  {
    name: constants.GO_TO_PRODUCTS,
    keys: ['G', 'P'],
    label: ['Go to', ' Products'],
    icon: icons.LaptopIcon,
  },
  {
    name: constants.GO_TO_HOME,
    keys: ['G', 'H'],
    label: ['Go to ', 'Home'],
    icon: icons.SlashIcon,
  },
  {
    name: constants.GO_TO_ARTICLES,
    keys: ['G', 'R'],
    label: ['Go to ', 'Articles'],
    icon: icons.BookIcon,
  },
  {
    name: constants.GO_TO_FEY,
    keys: [],
    label: ['Go to ', ' Feyapp.com'],
    external: true,
    icon: icons.FeyIcon,
  },
  {
    name: constants.GO_TO_TWITTER,
    keys: [],
    label: ['Go to ', ' Twitter'],
    external: true,
    icon: icons.TwitterIcon,
  },
  {
    name: constants.ESCAPE,
    keys: ['escape'],
    label: ['Close ', 'Command Line'],
    icon: icons.GoToIcon,
  },
]

export default shortcuts
