import React from 'react'
import styled from 'styled-components'

function Gradient(props: React.PropsWithChildren<{ gradient?: string }>) {
  return <Mask gradient={props.gradient}>{props.children}</Mask>
}

export default Gradient

const Mask = styled.span<{ gradient?: string }>`
  display: inline-block;
  background: ${p =>
    p.gradient
      ? p.gradient
      : 'linear-gradient(91.92deg, #BFA8A7 0.99%, #CFD3DE 34.85%, #ADBBD2 67.46%, #9FACAC 79.92%, #E0DBCE 93.48%)'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
