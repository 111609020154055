export const SHORTCUT = 'SHORTCUT'

export const COMMAND_LINE = 'COMMAND_LINE'

export const COMMAND_LINE_DEFAULT = 'COMMAND_LINE_DEFAULT'
export const COMMAND_LINE_READ = 'COMMAND_LINE_READ'
export const COMMAND_LINE_OPEN_SOURCE = 'COMMAND_LINE_OPEN_SOURCE'

export const GO_TO_HOME = 'GO_TO_HOME'
export const GO_TO_CAREERS = 'GO_TO_CAREERS'
export const GO_TO_PRODUCTS = 'GO_TO_PRODUCTS'
export const GO_TO_ABOUT = 'GO_TO_ABOUT'
export const GO_TO_ARTICLES = 'GO_TO_ARTICLES'
export const GO_TO_ARTICLE = 'GO_TO_ARTICLE'

export const GO_TO_NARATIVE = 'GO_TO_NARATIVE'
export const GO_TO_NARATIVE_GITHUB = 'GO_TO_NARATIVE_GITHUB'
export const GO_TO_FEY_LOGO = 'GO_TO_FEY_LOGO'
export const GO_TO_HOPPER_WWW = 'GO_TO_HOPPER_WWW'
export const GO_TO_HOPPER_MEDIA = 'GO_TO_HOPPER_MEDIA'

export const GO_TO_FEY = 'GO_TO_FEY'
export const GO_TO_TWITTER = 'GO_TO_TWITTER'
export const GO_TO_LINKEDIN = 'GO_TO_LINKEDIN'
export const GO_TO_INSTAGRAM = 'GO_TO_INSTAGRAM'

export const CONTACT = 'CONTACT'

export const ESCAPE = 'ESCAPE'
