import React, { createContext, useState, ReactChild } from 'react'

// it returns two components Provider and Consumer
export const ContactContext = createContext({
  showContact: false,
  toggleContact: (event?: Event) => {},
})

export function ContactProvider({ children }: { children: ReactChild }) {
  const [showContact, setShowContact] = useState(false)

  function toggleContact(event?: Event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    setShowContact(prevContact => !prevContact)
  }

  return (
    <ContactContext.Provider value={{ showContact, toggleContact }}>
      {children}
    </ContactContext.Provider>
  )
}
